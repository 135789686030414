export class SortObjectArrayValueConverter {
  public toView(array, sortByKey, sortDirection = 'ASC') {
    if (!array || array.constructor !== Array) {
      return;
    } else {

      return array.sort((a, b) => {

        let aSortByKey = sortByKey;
        let bSortByKey = sortByKey;

        if (sortByKey === 'NameEs' && !a[sortByKey]) {
          aSortByKey = 'NameEn';
        }
        if (sortByKey === 'NameEs' && !b[sortByKey]) {
          bSortByKey = 'NameEn';
        }
        if (sortByKey === 'NameEn' && !a[sortByKey]) {
          aSortByKey = 'Name';
        }
        if (sortByKey === 'NameEn' && !b[sortByKey]) {
          bSortByKey = 'Name';
        }

        if (a[aSortByKey] < b[bSortByKey]) {
          return sortDirection == 'DESC' ? 1 : -1;
        }
        if (a[aSortByKey] > b[bSortByKey]) {
          return sortDirection == 'DESC' ? -1 : 1;
        }
        return 0;

      });
    }
  }
}
