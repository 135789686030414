export class RoundNumberValueConverter {
  public toView(value) {
    if (!value) {
      return;
    } else {
      let parsedValue = parseFloat(value);
      if (isNaN(parsedValue))
        return parsedValue
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return value;
    }
  }
}
