import { LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import * as moment from 'moment';

const logger: Logger = LogManager.getLogger('utility');

type Input<T> = T | null | undefined;

export class Utility {
  areEqual<T>(obj1: Input<T>, obj2: Input<T>, debug = false) {
    if ((obj1 && !obj2) || (!obj1 && obj2)) {
      return false;
    }
    if (!obj1 && !obj2) {
      return true;
    }

    const changedProps: Array<string> = [];

    Object.keys(obj1).every((key) => {
      if (typeof obj1[key] == 'object' && obj1) {
        const objectIsEqual = this.areEqual(obj1[key], obj2[key], debug);

        if (!objectIsEqual) {
          if (debug) logger.debug('FOLLOWING KEY IS NOT EQUAL ON OBJECTS: ' + key, obj1[key], obj2[key]);
          changedProps.push('key: ' + key + ', obj1[key]: ' + obj1[key] + ', obj2[key]: ' + obj2[key]);
        }

        return objectIsEqual;
      } else if (!(Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] == obj2[key])) {
        if (debug) logger.debug('ORIGINAL OBJECT IS MISSING A KEY: ' + key);
        changedProps.push('key: ' + key + ', originalObject: ' + obj1[key] + ', realObject: ' + obj2[key]);
      }

      if (!Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] == obj2[key]) {
        if (debug) logger.debug('VALUES ARE NOT EQUAL: ' + key);
        changedProps.push('key: ' + key + ', originalObject: ' + obj1[key] + ', realObject: ' + obj2[key]);
        return false;
      }

      return true;
    });

    // for debugging
    if (debug && changedProps.length > 0) {
      logger.debug('changedprops', changedProps);
    }

    return changedProps.length == 0;
  }

  public areEqualDateFix<T>(object: Input<T>, dateFixes: Array<unknown>) {
    const objectToFix = object;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dateFixes.forEach((dateFix: any) => {
      if (!objectToFix[dateFix.key]) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      objectToFix[dateFix.key] = moment.default(objectToFix[dateFix.key]).format(dateFix.format || 'YYYY-MM-DD') as any;
    });

    return objectToFix;
  }
}
