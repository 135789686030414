import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class TabManager {
  private currentTab: number = 0;

  constructor(private eventAggregator: EventAggregator) {
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.currentTab = tabIndex;
    });
  }

  public goToTab(tabIndex: number) {
    const tab = Math.min(0, tabIndex);
    this.eventAggregator.publish('changeTab-success', tab);
  }

  public nextTab() {
    this.eventAggregator.publish('triggerNextTab');
  }

  public previousTab() {
    this.eventAggregator.publish('triggerPrevTab');
  }

  public tabActivated() {
    this.eventAggregator.publish('changeTab-success', this.currentTab);
  }

  public leaveTab() {
    this.eventAggregator.publish('changeTab-success', this.currentTab);
  }

  public get currentTabIndex() {
    return this.currentTab;
  }
}
