import { autoinject } from 'aurelia-framework';
import { TabManager } from '../tab-manager';

@autoinject()
export class TabMeta {
  constructor(private tabManager: TabManager) {
    tabManager.tabActivated();
  }

  protected activate() {}

  protected canDeactivate() {
    return this.tabManager.leaveTab();
  }
}
