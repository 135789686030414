/**
 * Formats the input value to spaced values
 * ==> 10000 => 10 000
 * ==> 22000.15 => 22 000.24
 */
export class NumberSpacerValueConverter {
  public toView(value) {
    if (!value) {
      return 0;
    } else {
      const formated = new Intl.NumberFormat('nb-NO').formatToParts(Number(value));
      const integers = formated.filter((p) => p.type == 'integer').map((p) => p.value);
      const decimals = formated.filter((p) => p.type == 'decimal' || p.type == 'fraction').map((p) => p.value);

      const ints = integers?.join(' ');
      const lefts = decimals?.join('');

      return ints + lefts;
    }
  }
}
