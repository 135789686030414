export class DecimalsValueConverter {
  public toView(value, decimals) {
    if (!value) {
      return;
    } else {
      let parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) console.log('parsedValue', parsedValue, decimals);
      return parsedValue
        .toFixed(decimals)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  }
}
