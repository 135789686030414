export class PriceFormatValueConverter {
  private toView(value, format) {
    if (!value && value !== 0) {
      return;
    }

    var formatter = new Intl.NumberFormat('nb-NO', {
      style: 'decimal',
      minimumFractionDigits: 2,
      minimumIntegerDigits: 1
      // the default value for minimumFractionDigits depends on the currency
      // and is usually already 2
    });

    return formatter.format(value);    
  }
}
