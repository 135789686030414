export class AddressInlineValueConverter {
  public toView(company) {
    if (!company) {
      return;
    }
    let str = '';
    if (company.Address1) {
      str += ' ' + company.Address1;
    }
    if (company.Address2) {
      str += ', ' + company.Address2;
    }
    if (company.Zip) {
      str += ', ' + company.Zip;
    }
    if (company.City) {
      str += ' ' + company.City;
    }
    if (company.Country) {
      str += ', ' + company.Country;
    }
    return str;
  }
}
