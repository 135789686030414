import { autoinject } from 'aurelia-framework';
import { TabManager } from '../tab-manager';

@autoinject()
export class TabCriteriaForValidity {
  constructor(protected tabManager: TabManager) {
    tabManager.tabActivated();
  }

  protected canDeactivate() {
    return this.tabManager.leaveTab();
  }
}
