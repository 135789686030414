import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { MooringStation } from 'models/MooringStation';
import { MooringStationService, ErrorService } from 'services';
import { ToastService } from 'services/toast-service';

@autoinject()
export class MooringsStationsListView {
  protected ready = false;

  protected mooringStation: MooringStation = new MooringStation();
  protected id: number | undefined;

  constructor(
    private mooringStationsService: MooringStationService,
    private errorService: ErrorService,
    private router: Router,
    private toast: ToastService,
    private i18n: I18N
  ) {}

  protected activate({ Id }: { Id: number | undefined }) {
    this.id = Id;
    void this.getMooringStation();
  }

  deactivate() {}

  protected async save() {
    try {
      if (!this.mooringStation) return;
      if (this.mooringStation.Id) {
        await this.mooringStationsService.update(this.mooringStation, this.mooringStation.Id);
      } else {
        const station = await this.mooringStationsService.create(this.mooringStation);
        this.router.navigateToRoute('edit-mooring-station', { Id: station.Id });
      }
      this.toast.showSuccess('general.saved');
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getMooringStation() {
    if (!this.id) {
      this.ready = true;
      return;
    }
    try {
      this.mooringStation = await this.mooringStationsService.get(this.id);
      this.ready = true;
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
