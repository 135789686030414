import { PLATFORM } from 'aurelia-framework';

export const tabRoutes = [
  {
    route: [''],
    name: 'tab-articles',
    moduleId: PLATFORM.moduleName('./article-register/tab-articles'),
    tabindex: 0,
    entityShortName: 'articles-tab-articles',
  },
  {
    route: ['/meta'],
    name: 'tab-meta',
    moduleId: PLATFORM.moduleName('./article-register/tab-meta'),
    tabindex: 1,
    entityShortName: 'articles-tab-meta',
  },
  {
    route: ['/materials'],
    name: 'tab-materials',
    moduleId: PLATFORM.moduleName('./article-register/tab-materials'),
    tabindex: 2,
    entityShortName: 'articles-tab-materials',
  },
  {
    route: ['/criteria-for-validity'],
    name: 'tab-criteria-for-validity',
    moduleId: PLATFORM.moduleName('./article-register/tab-criteria-for-validity'),
    tabindex: 3,
    entityShortName: 'criteria-for-validity',
  },
  {
    route: ['/suppliers'],
    name: 'tab-suppliers',
    moduleId: PLATFORM.moduleName('./article-register/tab-suppliers'),
    tabindex: 4,
    entityShortName: 'articles-tab-suppliers',
  },
];
