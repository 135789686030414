export class titleCaseValueConverter {
  toView(value) {
    if(!value){
      return
    }
    return value.toLowerCase()
      .split(" ")
      .map(function(word) {
        return word[0].toUpperCase() + word.substr(1);
      })
      .join(" ");
  }
}
