export class JsonStringValueConverter {
  public toView(value: object) {
    if (!value) {
      return '';
    }

    try {
      return JSON.stringify(value, null, 2).trim();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return '';
    }
  }
}
