import * as moment from 'moment';

export class DateFormatValueConverter {
  toView(value, format) {
    if (!value) {
      return;
    }
    if (!format) {
      format = 'DD.MM.YYYY';
    }
    return moment.default(value).format(format);
  }
}
