import { LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

const logger: Logger = LogManager.getLogger("app");

export class selectoptionsValueConverter {
  toView(array, valueField, labelField, default_values) {
    logger.debug(array, valueField, labelField, default_values);
    let choices = []
    array.forEach(option => {

      let selected = false;
      if (option[valueField] == default_values) {
        selected = true;
      }

      let choice = {
        value: option[valueField],
        label: option[labelField],
      }
      choices.push(choice);
    });

    return choices;
  }
}
