import * as moment from 'moment';

export class DateTimeFormatValueConverter {
  private toView(value, format) {
    if (!value) {
      return;
    }
    if (!format) { format = 'DD.MM.YYYY HH:mm:ss'; }
    return moment.utc(value).local().format(format);
  }
}
