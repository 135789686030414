export class MaxLengthValueConverter {
  public toView(value: string, maxLength: number) {
    if (!value) {
      return null;
    } else if (value.length > maxLength) {
      return value.substr(0, maxLength) + '...';
    } else {
      return value;
    }
  }
}
