export class PriceDetailFormatValueConverter {
  private toView(details, format) {
    if (!details) {
      return;
    }

    let formatter = new Intl.NumberFormat('nb-NO', {
      style: 'decimal',
      minimumFractionDigits: 2,
      minimumIntegerDigits: 1
      // the default value for minimumFractionDigits depends on the currency
      // and is usually already 2
    });

    let fullTextValues = [];

    details.forEach(detail => {
      let rowInfo = [];
      if (detail.Type) {
        rowInfo.push('Type: ' + detail.Type);
      } 
      if (detail.Placement) {
        rowInfo.push('Placement: ' + detail.Placement);
      }
      if (detail.Specification) {
        rowInfo.push('Specification: ' + detail.Specification);
      }
      
      rowInfo.push('Costprice Navision/Delta: ' 
        + (detail.CostPriceNavision ? formatter.format(detail.CostPriceNavision) : 'unknown') + '/'
        + (detail.CostPriceDelta ? formatter.format(detail.CostPriceDelta) : 'unknown'));
    
      if (detail.PriceComment) {
        rowInfo.push(detail.PriceComment);
      }
      if (detail.Weight) {
        rowInfo.push('Weight: ' + formatter.format(detail.Weight) + ' kg');
      }
      if (detail.Amount) {
        rowInfo.push('Amount: ' + formatter.format(detail.Amount));
      }
      if (detail.Length) {
        rowInfo.push('Length: ' + formatter.format(detail.Length) + ' m');
      }
      if (detail.Area) {
        rowInfo.push('Area: ' + formatter.format(detail.Area) + ' m2');
      }    
      if (detail.Quantity) {
        rowInfo.push('Quantity: ' + formatter.format(detail.Quantity));
      }

      fullTextValues.push(rowInfo.join(', '));    
    });
    
    return fullTextValues.join('<br/>');    
  }
}
