import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

@autoinject
export class NameSearchValueConverter {

    constructor(private i18N: I18N) {}

    toView(items, search) {
        if (search && search != '') {
            return items.filter((item) => {
                const itemName = this.getLocaleName(this.i18N.getLocale(), item);
                if (itemName.toLowerCase().replace(/\s/g, '').includes(search.toLowerCase().replace(/\s/g, ''))) {
                    return true;
                } else {
                    return false;
                }
            });
        } else {
            return items;
        }
    }

    private getLocaleName(lang, row) {    
        switch (lang) {
          case 'nb-NO':
            return row['Name'];
          case 'en':
            return row['Name' + 'En'] ? row['Name' + 'En'] : row['Name'];
          case 'es':
            return row['Name'  + 'Es'] ? row['Name'  + 'Es'] : row['Name' + 'En'];
          default:
            return row['Name'];
        }
      }

}